import React, { useEffect, useState } from 'react';
import CustomInput from '../../Components/ReusableComponents/CustomInput'
import { FaLongArrowAltRight } from 'react-icons/fa';
import Layout from '../../Layout/Layout';
import { createUser } from '../../AxiosHelper/apiHelper';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin } from '../../Redux/UserRedux/userAction';
import recentTestimony from '../../Icons/recentTestimony.png'
import googleIcon from '../../Icons/google.png'
import PulseLoader from "react-spinners/PulseLoader";


const SignUp = () => {
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const origin = (location?.state?.from?.pathname) || '/home';
    const { adminUser } = useSelector(state => state.admin);

    useEffect(() => {
        adminUser?._id ? navigate(origin) : dispatch(autoLogin());
    }, [navigate, origin, dispatch, adminUser])

    const handelOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setForm({
            ...form,
            [name]: value
        });
    }

    const handelOnSubmit = async (e) => {
        setLoading(true);
        try {
            e.preventDefault();
            const response = await createUser(form);
            const { status, message } = response;
            toast[status](message);
            status === 'success' && navigate('/signin')
        } catch (error) {
            toast.error(error.message)
        }
        finally {
            setLoading(false);
        }
    }

    const inputs = [
        {
            label: 'Full Name',
            name: 'fullName',
            type: 'text',
            required: true,
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'johnDoe@gmail.com',
            required: true,
        },
        {
            label: 'Password',
            name: 'password',
            type: 'password',
            placeholder: '******',
            required: true,
        },

    ];

    return (
        <Layout>
            <div className='flex flex-wrap min-h-screen font-sans'>
                <div className='w-full md:w-1/2 bg-customGray order-2 rounded md:order-1'>
                    <div className='flex flex-col mt-6 mx-auto items-center w-4/5 justify-center '>
                        <h1 className='text-lg font-bold'>
                            One of the recent testimonies from our
                            users.
                        </h1>
                        <span className='w-full mx-auto'>
                            <img
                                className='mt-6 shadow-lg border-black border-t-2 border-r-4 border-b-4 border-l-2'
                                src={recentTestimony}
                                alt='Recent Testimony Banner'
                            />
                        </span>
                    </div>
                </div>

                <div className='md:w-1/2 bg-white rounded order-1 mx-auto pt-30 pb-12 md:pt-28 md:pb-15 md:order-2'>
                    <div className='mt-6 mx-auto text-center items-center w-4/5'>
                        <h1 className='text-3xl font-bold'>
                            Explore Testimonypal with a 14-day free trial!
                        </h1>
                        <Link
                            to='/pricing'
                            className='block text-customBlue p-1 underline text-sm underline-offset-3 hover:cursor-pointer'
                        >
                            See our pricing{" "}
                            <FaLongArrowAltRight className=' inline-block' />
                        </Link>
                        <form className='mt-10' onSubmit={handelOnSubmit}>
                            {inputs.map((input, index) => (
                                <CustomInput
                                    {...input}
                                    key={index}
                                    onChange={handelOnChange}
                                />
                            ))}

                            <button
                                className='w-full p-2 mt-2 border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 rounded hover:cursor-pointer bg-customMainWhite hover:scale-105 transform transition-transform duration-400 flex justify-center items-center '
                                type='submit'
                                disabled={loading}
                            >
                                {loading ?
                                    (<div className='px-4 flex items-center justify-center gap-2'>
                                        Loading
                                        <PulseLoader
                                            color="#0b1215"
                                            loading={loading}
                                            size={10}
                                        />
                                    </div>
                                    ) : "Login"}

                            </button>
                        </form>
                        {/* Social signup button  */}
                        <div className='w-full p-2 mt-2 border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 rounded hover:cursor-pointer bg-customMainWhite hover:scale-105 transform transition-transform duration-400 flex justify-center items-center px-4 flex items-center justify-center gap-2'>
                            <img
                                className='inline-block'
                                alt='googleIcon'
                                style={{ height: "30px" }}
                                src={googleIcon}
                            />
                            Sign up with Google
                        </div>

                        <p className='block text-customGrayWhite p-2 underline text-sm underline-offset-3 hover:cursor-pointer text-end'>
                            <Link to='/signIn'>Already a user?</Link>
                        </p>
                    </div>
                </div>


            </div>
        </Layout>
    );
};

export default SignUp;
