import React, { useEffect, useState } from 'react'
import { IoDownload, IoClose } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { getFormsAction, uploadLogoInFormAction } from '../../../Redux/Testimony/testimonyAction';
import { useParams } from 'react-router-dom';

const LogoUploader = () => {
    const { id } = useParams();
    const _id = id
    const [serverImg, setServerImg] = useState(null);
    const [images, setImages] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFormsAction())
    }, [dispatch]);


    const handelOnRemoveImage = () => {
        setServerImg(null)
        setImages([]);
    }

    const handelOnDrop = (e) => {
        e.preventDefault();
        const { files } = e.dataTransfer;
        setServerImg(URL.createObjectURL(files[0]));
        setImages(files);
    }

    const handelOnDragOver = (e) => {
        e.preventDefault();
    };

    const handelOnFileChange = (e) => {
        const { files } = e.target;
        if (files.length > 0) {
            const imgUrl = URL.createObjectURL(files[0]);
            setServerImg(imgUrl)
        }
        setImages(files)
    };

    const handelOnSubmit = (e) => {
        e.preventDefault();
        for (const image of images) {
            dispatch(uploadLogoInFormAction(image, _id));
        }
        setImages([]);
    }

    return (
        <form onSubmit={handelOnSubmit} encType='multipart/form-data'>
            <input accept='image/*' type='file' name='brandLogo' id='fileInput' style={{ display: 'none' }} onChange={handelOnFileChange} />
            {serverImg ? (
                <div className='relative flex flex-col gap-4'>
                    <img
                        src={serverImg}
                        className='w-full h-full rounded-lg border-dotted' crossOrigin='anonymous' alt='logoImg' />
                    <IoClose
                        className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 cursor-pointer text-3xl"
                        onClick={handelOnRemoveImage}
                    />
                    <button className='py-2 px-4 text-xl gap-2 bg-customWhite rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2 scale-90 hover:scale-100 transform transition-transform duration-400' type='submit'>
                        Save Logo
                    </button>
                </div>
            ) : (
                <div>
                    <IoDownload
                        className="relative h-40 w-80 text-lg font-bold text-senjaGray hover:text-gray-900 p-4 border-dashed border-2 border-gray-300 hover:border-gray-500 rounded-lg transition-all duration-200"
                        onDragOver={handelOnDragOver}
                        onDrop={handelOnDrop}
                        onClick={() => document.getElementById('fileInput').click()}
                    />
                </div>
            )}
        </form>
    )
}

export default LogoUploader