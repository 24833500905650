import React from 'react'
import Header from '../Pages/Header/Header'
import Footer from '../Pages/Footer/Footer'

const Layout = ({ children }) => {
    return (
        <div className='min-h-screen bg-customWhite'>
            <Header />
            {/* <div className='flex-1'> */}
            <div className=' min-h-[70vh] font-sans'>{children}</div>
            <Footer />
        </div>
    )
}

export default Layout;


