import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profileImageUploaderModal: false,
}

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setShowModal: (state, { payload }) => {
            state.profileImageUploaderModal = payload;
        },
    },
});

const { reducer, actions } = modalSlice;
export const { setShowModal } = actions;
export default reducer;