import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../Layout/Layout'
import ClipLoader from "react-spinners/ClipLoader";
import { FaCheck } from "react-icons/fa6";
import { useSearchParams } from 'react-router-dom';
import { emailVerification } from '../../AxiosHelper/apiHelper';

const EmailVerification = () => {
    const [queryParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const obj = {
            emailValidationCode: queryParams.get("c"),
            email: queryParams.get("e").replace("%40", "@")
        };

        (async () => {
            try {
                const result = await emailVerification(obj);
                setResponse(result);
            } catch (error) {
                console.error("Error during email verification:", error);
                setResponse({ error: "An error occurred during email verification." });
            } finally {
                setLoading(false);
            }
        })();
    }, [queryParams]);

    return (
        <Layout>
            <div className='w-full rounded mx-auto flex items-center justify-center pt-32 pb-12 md:pt-38 md:pb-20'>
                <div className='max-w-md mx-auto bg-white py-8 px-4 shadow sm:rounded sm:px-10 border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                    {loading ?
                        <div className='flex items-center justify-center gap-2'>
                            {response.message}
                            <ClipLoader
                                color="#0b1215"
                                loading={loading}
                                size={20}
                            />
                        </div> :
                        <div className='flex text-customGreen items-center justify-center gap-2 text-xl'>
                            <p>{response.message}</p>< FaCheck className='inline-block' />
                        </div>
                    }
                </div>
            </div>
        </Layout >
    )
}

export default EmailVerification;