import React, { useState } from 'react'
import CustomInput from '../../../Components/ReusableComponents/CustomInput'
import { updatePasswordAction } from '../../../Redux/UserRedux/userAction';
import { useDispatch } from 'react-redux';

const ResetPassword = () => {
    const [form, setform] = useState({});
    const dispatch = useDispatch();

    const handelOnChange = (e) => {
        const { name, value } = e.target;
        setform({ ...form, [name]: value })
    }

    const handelOnSubmit = (e) => {
        e.preventDefault();
        dispatch(updatePasswordAction(form))
        setform({})

    }

    const passwordInputs = [
        {
            label: 'Current password',
            name: 'currentPassword',
            type: 'password',
            placeholder: '******',
            required: true,
        },
        {
            label: 'New password',
            name: 'newPassword',
            type: 'password',
            placeholder: '******',
            required: true,
        },

    ];

    return (
        <form className='mt-3' onSubmit={handelOnSubmit}>
            {passwordInputs.map((input, index) => (
                <CustomInput {...input} key={index} onChange={handelOnChange} />
            ))}
            <button className='border rounded-lg p-2 shadow-lg bg-customBlue  border-black  border-r-4 border-b-4' type='submit'>
                Update password
            </button>
        </form>
    )
}

export default ResetPassword