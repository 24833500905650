import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { MdOutlineModeEdit } from 'react-icons/md';
import { FaPaintBrush } from "react-icons/fa";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { FaMobileAlt } from "react-icons/fa";
import { HiComputerDesktop } from "react-icons/hi2";
import { PrivatePages } from '../PrivatePage/PrivatePages';
import { ChromePicker } from 'react-color';
import { LuClipboardEdit } from "react-icons/lu";
import { setShowModal } from '../../Redux/SystemUtils/modalSlice';
import Modal from '../../Components/Modal/Modal';
import { getFormsById } from '../../AxiosHelper/apiHelper';
import { setCurrentTestimony } from '../../Redux/Testimony/testimonySlice';
import { saveFormsAction } from '../../Redux/Testimony/testimonyAction';

const TestimonyCollectorLayout = ({ children }) => {
    const { currentTestimony } = useSelector((state) => state.testimony);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [currentInput, setCurrentInput] = useState({});
    const [isDesktop, setIsDesktop] = useState(true);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState({ left: false, right: false });

    useEffect(() => {
        const fetchFormDetails = async () => {
            try {
                const { forms } = await getFormsById(id);
                setCurrentInput(forms);
                dispatch(setCurrentTestimony(forms));
            } catch (error) {
                console.error("Error fetching form details:", error);
            }
        };
        fetchFormDetails();
    }, [id, dispatch]);


    const toggleColorPicker = (side) => {
        setShowColorPicker(prevState => ({
            ...prevState,
            [side]: !prevState[side]
        }));
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const updatedInput = {
            ...currentInput,
            [name]: value
        };
        setCurrentInput(updatedInput);
        dispatch(setCurrentTestimony(updatedInput));
    };

    const colorChange = (name, color) => {
        const updatedInput = {
            ...currentInput,
            [name]: color.hex,
        };
        setCurrentInput(updatedInput);
        dispatch(setCurrentTestimony(updatedInput));
    };

    const keyDown = (e) => {
        const { name, value } = e.target;
        if (e.key === 'Enter') {
            e.preventDefault();
            const updatedInput = {
                ...currentInput,
                [name]: value + '\n'
            };
            setCurrentInput(updatedInput);
            dispatch(setCurrentTestimony(updatedInput));
        }
    };

    const handelOnLogoUpload = () => {
        dispatch(setShowModal(true));
    };

    const toggleDropdown = (index) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    };

    const handelOnDesktop = () => {
        setIsDesktop(!isDesktop);
    };

    const handelOnSumbit = (e) => {
        e.preventDefault();
        const obj = {
            _id: currentTestimony._id,
            headings: currentTestimony.headings,
            leftColor: currentTestimony.leftColor,
            rightColor: currentTestimony.rightColor,
            questions: currentTestimony.questions,
        }
        saveFormsAction(obj)
    };

    return (
        <PrivatePages>
            <Modal heading={'Upload Your Logo'} />
            <div className='h-screen'>
                <div className="relative flex flex-col md:flex-row justify-between">
                    <Link
                        to='/testimonyCollector/text'
                        className="absolute flex items-center text-customGrayWhite p-4 left-0 top-0 text-sm gap-2"
                    >
                        <FaArrowLeft />Forms
                    </Link>

                    <div className="w-full md:w-1/3 min-h-screen font-sans mt-16 p-5 gap-12 overflow-y-auto h-32 mr-[30px]">
                        <h1 className="items-center p-2 text-xl font-bold flex items-center gap-2">
                            Testimony Text Form <MdOutlineModeEdit />
                        </h1>
                        <form className='h-[80vh] overflow-y-auto flex flex-col justify-between' onSubmit={handelOnSumbit}>
                            <ul className="p-2 text-xl space-y-4">
                                <li>
                                    <div className='flex gap-3 items-center border-b hover:cursor-pointer' onClick={() => toggleDropdown(0)}>
                                        {openDropdownIndex === 0 ? (
                                            <RiArrowDropUpLine className='text-3xl text-customGrayWhite' />
                                        ) : (
                                            <RiArrowDropDownLine className='text-3xl text-customGrayWhite' />
                                        )}
                                        <FaPaintBrush className='text-customPurple' />
                                        <p>Design</p>
                                    </div>
                                    <div>
                                        {openDropdownIndex === 0 && (
                                            <div className="p-2">
                                                <div>
                                                    <p className='text-base text-senjaGray mb-1'>Logo</p>
                                                    <div className='border-2 w-28 h-28 flex justify-center items-center' onClick={handelOnLogoUpload}>
                                                        <img src={currentTestimony?.imageUrl} className='w-full h-full' alt='profileImg' />
                                                    </div>
                                                </div>
                                                <div className='flex gap-2 mt-2'>
                                                    <div className='left w-1/2'>
                                                        <p className='text-base text-senjaGray mb-1'>Left Color</p>
                                                        <div>
                                                            <div className='text-sm h-10 rounded uppercase text-senjaGray w-full border flex items-center gap-2'>
                                                                <div className='ml-1'>
                                                                    <div
                                                                        className="relative w-5 h-5 rounded-full cursor-pointer"
                                                                        style={{ backgroundColor: currentInput.leftColor }} onClick={() => toggleColorPicker("left")}
                                                                    >
                                                                        {showColorPicker.left && (
                                                                            <div className="absolute z-10 top-10 left-[-10px]">
                                                                                <ChromePicker
                                                                                    name="leftColor"
                                                                                    color={currentInput.leftColor}
                                                                                    onChange={(color) => colorChange("leftColor", color)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <input type="text" className='w-full focus:outline-none'
                                                                    name="leftColor"
                                                                    placeholder="#EE99C2"
                                                                    value={currentInput.leftColor}
                                                                    onChange={handleOnChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='Right w-1/2'>
                                                        <p className='text-base text-senjaGray mb-1'>Right Color</p>
                                                        <div>
                                                            <div className='text-sm h-10 rounded uppercase text-senjaGray w-full border flex items-center gap-2'>
                                                                <div className='ml-1'>
                                                                    <div
                                                                        className="relative w-5 h-5 rounded-full cursor-pointer"
                                                                        style={{ backgroundColor: currentInput.rightColor }}
                                                                        onClick={() => toggleColorPicker("right")}
                                                                    >
                                                                        {showColorPicker.right && (
                                                                            <div className="absolute z-10 top-10 left-[-20px]">
                                                                                <ChromePicker
                                                                                    color={currentInput.rightColor}
                                                                                    onChange={(color) => colorChange("rightColor", color)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <input type="text" className='w-full focus:outline-none'
                                                                    name="rightColor"
                                                                    placeholder="#A388EE"
                                                                    value={currentInput.rightColor}
                                                                    onChange={handleOnChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </li>

                                <li>
                                    <div className='flex gap-3 items-center border-b hover:cursor-pointer' onClick={() => toggleDropdown(1)}>
                                        {openDropdownIndex === 1 ? (
                                            <RiArrowDropUpLine className='text-3xl text-customGrayWhite' />
                                        ) : (
                                            <RiArrowDropDownLine className='text-3xl text-customGrayWhite' />
                                        )}
                                        <LuClipboardEdit className='text-customGreen' />
                                        <p>Edit Message</p>
                                    </div>
                                    <div>
                                        {openDropdownIndex === 1 && (
                                            <div className="p-2">
                                                <div className=''>
                                                    <p className='text-base text-senjaGray mb-1'>Welcome Page Title</p>
                                                    <input className="text-base w-full border p-2 outline-none rounded"
                                                        placeholder='ex. Share your testimonial' type="text" value={currentInput.headings} name="headings" onChange={handleOnChange} />
                                                </div>
                                                <div className='mt-5'>
                                                    <p className='text-base text-senjaGray mb-1'>Introduction Message</p>
                                                    <textarea
                                                        className="text-base text-senjaGray p-2 w-full h-48 border p-1 outline-none rounded resize-none text-black"
                                                        type="text"
                                                        placeholder='Simply type your message you want to ask to your user'
                                                        value={currentInput.questions}
                                                        name="questions"
                                                        onChange={handleOnChange}
                                                        onKeyDown={keyDown}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                            <button className='p-2 text-xl gap-2 bg-customWhite rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2 scale-90 hover:scale-100 transform transition-transform duration-400' type='submit'>
                                Save changes
                            </button>
                        </form>
                    </div>

                    <div className={`relative flex-1 items-center flex bg-customGray p-3 place-content-center`}>
                        <div className='absolute flex top-10 gap-2 border p-2 text-3xl hover:cursor-pointer' onClick={handelOnDesktop}>
                            <HiComputerDesktop className={`${isDesktop ? "text-customPurple" : ""}`} />
                            <FaMobileAlt className={`${isDesktop ? "" : "text-customPurple"}`} />
                        </div>
                        <div className={`border-4 p-5 rounded-lg shadow-xl border-black min-h-[600px] ${isDesktop ? 'w-11/12 transition-all duration-500' : 'w-[479px] transition-all duration-500'} flex items-center justify-center relative`}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </PrivatePages>
    );
};

export default TestimonyCollectorLayout;
