import React, { useEffect, useState } from 'react'
import CustomInput from '../../Components/ReusableComponents/CustomInput'
import waveImg from '../../Icons/wave.png'
import Layout from '../../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin } from '../../Redux/UserRedux/userAction';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PulseLoader from "react-spinners/PulseLoader";
import { loginAdminUser } from '../../AxiosHelper/apiHelper';
import { setAdminUser } from '../../Redux/UserRedux/userSlice';
import { toast } from "react-toastify";


const SignIn = () => {
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const origin = (location?.state?.from?.pathname) || '/home';
    const { adminUser } = useSelector(state => state.admin);

    useEffect(() => {
        adminUser?._id ? navigate(origin) : dispatch(autoLogin());
    }, [navigate, origin, dispatch, adminUser])


    const handelOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setForm({
            ...form,
            [name]: value
        });
    }

    const handelOnSubmit = async (e) => {
        setLoading(true);
        try {
            e.preventDefault();
            const resultPromise = await loginAdminUser(form);
            const { status, message, user, accessJWT, refreshJWT } = resultPromise;
            toast[status](message);
            if (status === 'success') {
                sessionStorage.setItem('accessJWT', accessJWT)
                localStorage.setItem('refreshJWT', refreshJWT)
                dispatch(setAdminUser(user))
            }
        } catch (error) {
            toast.error("An error occurred during login. Please try again later.");
        }
        finally {
            setLoading(false);
        }
    }

    const inputs = [
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'johnDoe@gmail.com',
            required: true,
        },
        {
            label: 'Password',
            name: 'password',
            type: 'password',
            placeholder: '******',
            required: true,
        },
    ];

    return (
        <Layout>
            <div className='w-full rounded mx-auto flex items-center justify-center pt-32 pb-12 md:pt-38 md:pb-20'>
                <div className='max-w-md mx-auto bg-white py-8 px-4 shadow sm:rounded sm:px-10 border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                    <h1 className='text-2xl font-sans font-bold flex items-center gap-2'>
                        Welcome back
                        <img
                            src={waveImg}
                            className='inline-block'
                            alt='handWave'
                            style={{ height: "40px" }}
                        />
                    </h1>
                    <form className='mt-5' onSubmit={handelOnSubmit}>
                        {inputs.map((input, index) => (
                            <CustomInput
                                {...input}
                                key={index}
                                onChange={handelOnChange}
                            />
                        ))}
                        <button
                            className='w-full p-2 mt-2 border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 rounded hover:cursor-pointer bg-customMainWhite hover:scale-105 transform transition-transform duration-400 flex justify-center items-center '
                            type='submit'
                            disabled={loading}
                        >
                            {loading ?
                                (<div className='px-4 flex items-center justify-center gap-2'>
                                    Loading
                                    <PulseLoader
                                        color="#0b1215"
                                        loading={loading}
                                        size={10}
                                    />
                                </div>
                                ) : "Login"}

                        </button>
                    </form>

                    <p className='text-center block p-1 underline text-sm underline-offset-3 hover:cursor-pointer'>
                        <Link to='/resetPassword'>
                            Forgot Password?
                        </Link>
                    </p>

                    <p className='block  p-2 underline text-sm underline-offset-3 hover:cursor-pointer text-end'>
                        <Link to='/signUp'>New user?</Link>
                    </p>
                </div>
            </div>
        </Layout>

    )
}

export default SignIn