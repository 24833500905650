import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const StrProfile = () => {
    const { adminUser } = useSelector((state) => state.admin);

    return (
        <div className='mt-3 border rounded p-2 bg-green text-sm leading-7'>
            <p className='text-xl'>My Subscription details</p>
            <div className='flex gap-2'>
                <p>Status :</p>
                <p>{adminUser?.subscription?.status}</p>
            </div>
            <div className='flex gap-2'>
                <p>Plan Type:</p>
                <p>{adminUser?.subscription?.plan}</p>
            </div>
            <div className='border rounded-lg p-2 shadow-lg bg-originalBlack text-white border-black border-r-4 border-b-4 max-w-40 flex items-center justify-center' type='submit'>
                {
                    adminUser?.subscription?.status === 'inactive' ? (
                        <Link to='/pricing'>
                            <button className='w-20'>Activate </button>
                        </Link>
                    ) : (
                        <div>
                            <button className='min-w-26'>Cancel Subscription</button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default StrProfile;
