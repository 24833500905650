import React, { useEffect } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../Layout/Layout';
import { autoLogin } from '../../Redux/UserRedux/userAction';

const HeroSection = () => {
    const { adminUser } = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!adminUser) {
            dispatch(autoLogin());
        }
    }, [dispatch, adminUser]);

    return (
        <Layout>
            <div className='text-center pt-30 pb-12 md:pt-38 md:pb-20'>
                <div className='mx-auto max-w-screen-sm p-4 '>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='mt-20 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl '>
                            Your all in one{" "}
                            <span className='text-customPurple'>
                                testimonial
                            </span>{" "}
                            from customers
                        </div>
                        <p className='mt-3 text-lg font-sans text-senjaGray'>
                            Gather testimonials automically publish it to your website No coding or design skills required.
                            <span className='text-orange'>
                                {" "}
                                Solo founder, Creator or Seller
                            </span>
                            , collecting
                            text and video testimonials
                        </p>
                        <div>
                            {adminUser && adminUser?._id ? (
                                <Link
                                    to='/home'
                                    className='text-lg p-2 bg-white inline-block mt-3 hover:scale-110 transform transition-transform duration-400 
                       border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded hover:cursor-pointer'
                                >
                                    Go back to dashboard
                                </Link>
                            ) : (
                                <Link
                                    to='/signup'
                                    className='text-lg p-2 bg-white inline-block mt-3 hover:scale-110 transform transition-transform duration-400 
                            border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded hover:cursor-pointer'
                                >
                                    Sign up for Free
                                </Link>
                            )}

                            <Link
                                to='/pricing'
                                className='block p-1 underline text-sm underline-offset-3 hover:cursor-pointer'
                            >
                                See our pricing{" "}
                                <FaLongArrowAltRight className=' inline-block' />
                            </Link>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout >
    )
}

export default HeroSection