import React from 'react';
import HeroSection from './HeroSection';


const Hero = () => {
    return (
        <HeroSection />
    );
}

export default Hero;
