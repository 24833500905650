import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Hero from './Pages/Hero/HomePage';
import SignUp from './Pages/SignUpSignin/SignUp';
import SignIn from './Pages/SignUpSignin/SignIn';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pricing from './Pages/Pricing/Pricing';
import Profile from './Pages/Profile/Profile';
import Home from './Pages/Home/Home';
import Features from './Pages/Features/Features';
import Integrations from './Pages/Integrations/Integrations';
import TextTestimony from './Pages/TestimonyCollector/Text/TextTestimony';
import DrakeForm from './Pages/TestimonyCollector/Text/Templete/DrakeForm';

import TestimonyCollector from './Pages/TestimonyCollector/TestimonyCollector';
import About from './Pages/About/About';
import EmailVerification from './Pages/EmailVerification/EmailVerification';
import ForgotPassword from './Pages/ResetPassword/ForgotPassword';
import Analytics from './Pages/Analytics/Analytics';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Hero />} />
          <Route path='signup' element={<SignUp />} />
          <Route path='signin' element={<SignIn />} />
          <Route path='pricing' element={<Pricing />} />
          <Route path='features' element={<Features />} />
          <Route path='integrations' element={<Integrations />} />
          <Route path='about' element={<About />} />
          <Route path='resetPassword' element={<ForgotPassword />} />

          <Route path='verifyEmail/' element={<EmailVerification />} />

          {/* Private Pages  */}
          <Route path='home' element={<Home />} />
          <Route path='profile' element={<Profile />} />
          <Route path='testimonyCollector' element={<TestimonyCollector />} />
          <Route path='analytics' element={<Analytics />} />

          <Route path='testimonyCollector/text' element={<TextTestimony />} />

          {/* Text Testimony Form */}
          <Route path='drakeForm/:id' element={<DrakeForm />} />
        </Routes>
      </BrowserRouter >
      <ToastContainer />
    </div >
  );
}

export default App;

