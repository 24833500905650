import React, { useState } from 'react';
import { FaEyeSlash } from "react-icons/fa6";
import { FaEye } from "react-icons/fa"

const CustomInput = ({ label, type, ...rest }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className='mb-3 flex flex-col relative bg-white'>
            <input
                {...rest}
                type={type === 'password' && showPassword ? 'text' : type}
                className='px-3 py-2 text-black border rounded-xl  focus:outline-none bg-white '
                placeholder={label}
            />

            {type === 'password' && (
                <span
                    className='cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2 '
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEye />
                        : <FaEyeSlash />
                    }
                </span>
            )}
        </div>
    );
};

export default CustomInput;
