import { configureStore } from '@reduxjs/toolkit'
import adminReducer from '../src/Redux/UserRedux/userSlice'
import testimonyReducer from '../src/Redux/Testimony/testimonySlice'
import modalReducer from '../src/Redux/SystemUtils/modalSlice'

export const store = configureStore({
    reducer: {
        admin: adminReducer,
        testimony: testimonyReducer,
        modal: modalReducer,
    },
})
