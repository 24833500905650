import React from 'react';
import { AdminLayout } from '../../../Layout/AdminLayout';
import { motion } from "framer-motion";
import { MdArrowDownward } from "react-icons/md";
import traditionalFormImg from '../../../Icons/TraditionalForm.png';
import { createFormAction } from '../../../Redux/Testimony/testimonyAction';
import { useNavigate } from 'react-router-dom';

const TextTestimony = () => {
    const navigate = useNavigate();

    const handelOnClick = async (e) => {
        const formName = e.target.closest('[name]').getAttribute('name');
        const data = {
            formName: formName
        }
        const response = await createFormAction(data)
        const { status, forms } = response
        if (status === 'success' && forms && forms._id) {
            const { _id } = forms;
            navigate(`/${formName}/${_id}`);
        }
    }

    return (
        <AdminLayout>
            <div className='bg-green border-b-4 border-black font-sans flex flex-wrap'>
                <div className='w-3/5 p-4 border-r border-black pt-30 pb-12 md:pt-38 md:pb-20 flex flex-col justify-center'>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='font-normal text-3xl mt-16'>
                            <h1>
                                Collect text testimonials easily with forms, available below!
                            </h1>
                            <p className='mt-10 text-sm w9/12 leading-5 text-lg text-customGrayWhite'>
                                Over 10 templates available
                            </p>
                        </div>
                    </motion.div>
                </div>
                {/* 3 bullet points on top of page  */}
                <div className='w-2/5 p-4 mt-10 leading-5'>
                    <ul className='flex flex-col flex-wrap'>
                        <li className='leading-5 flex gap-2 items-center text-center mb-3'>
                            <div className="w-6 h-6 flex items-center justify-center bg-black rounded-full border border-black text-white">
                                1
                            </div>
                            Select your favourite templates available below.
                        </li>
                        <li className='leading-5 flex gap-2 items-center text-center mb-3'>
                            <div className="w-6 h-6 flex items-center justify-center bg-black rounded-full border border-black text-white">
                                2
                            </div>
                            Make any changes required from default form.
                        </li>
                        <li className='leading-5 flex gap-2 items-center text-center mb-3'>
                            <div className="w-6 h-6 flex items-center justify-center bg-black rounded-full border border-black text-white">
                                3
                            </div>
                            Simply copy the link and send them.
                        </li>
                    </ul>

                    <div className='flex justify-center gap-2 w-32 items-center  mt-3 ml-5 text-center py-2 px-1 bg-customGreen inline-block border-black border-t-2 border-r-4 border-b-4 shadow-2xl border-l-2 rounded cursor-pointer'>Visit Below <MdArrowDownward /></div>
                </div>
            </div>

            {/* Black background */}
            <div className='h-48 bg-black relative flex justify-center items-center'>
                <div className='absolute top-16 flex flex-wrap p-4 gap-5 justify-center'>
                    {/* Drake Form  */}
                    <div name="drakeForm" className="w-[350px] height-[350px] border-black  border-r-2 border-b-2 rounded" onClick={handelOnClick}>
                        <div>
                            <img src={traditionalFormImg} alt='Traditional Form' className=" object-cover object-center" />
                        </div>
                        <p className='py-2 flex justify-center items-center border-black border-t font-sans'>Drake Form</p>
                    </div>
                    {/* SomethingForm Form  */}
                    <div name="somethingForm" className="w-[350px] height-[350px] border-black  border-r-2 border-b-2 rounded" onClick={handelOnClick}>
                        <div>
                            <img src={traditionalFormImg} alt='Traditional Form' className=" object-cover object-center" />
                        </div>
                        <p className='py-2 flex justify-center items-center border-black border-t font-sans'>Something Form</p>
                    </div>
                </div>
            </div>
        </AdminLayout >
    )
}

export default TextTestimony;
