import React from 'react'
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaRegCopyright } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='flex flex-col items-center text-center gap-4 py-4'>
            <h1 className='text-4xl font-bold drop-shadow-lg'>TP.</h1>
            <div>
                <ul className='flex justify-between gap-5 items-center text-lg'>
                    <li className='hover:cursor-pointer'>
                        <Link to='/features'>Features</Link>
                    </li>
                    <li className='hover:cursor-pointer'>
                        <Link to='/pricing'>Pricing</Link>
                    </li>
                </ul>

            </div>
            {/* social media */}

            <a href='https://x.com/TestimonyPal' target='_blank' rel="noopener noreferrer" className='flex text-lg gap-2 items-center border-2 p-2 rounded-lg bg-white hover:cursor-pointer'>
                <FaSquareXTwitter />Follow us on Twitter
            </a>

            {/* Copyright  */}
            <div className='flex text-sm gap-2 items-center text-customGrayWhite'>
                <FaRegCopyright />  2024 TestimonyPal - All Rights Reserved
            </div>
        </div>
    )
}

export default Footer