import React from 'react'
import { AdminLayout } from '../../Layout/AdminLayout'
import profileImg from '../../Icons/profile.png'
import ResetPassword from './ProfileComponent/ResetPassword';
import DeleteAccount from './ProfileComponent/DeleteAccount';
import ProfilePic from './ProfileComponent/ProfilePic';
import ProfileDetails from './ProfileComponent/ProfileDetails';
import StrProfile from './ProfileComponent/StrProfile';

const Profile = () => {

    return (
        <AdminLayout>
            <div className='p-5 border-black border-b-2 border-bold '>
                <div className='inline-block'>
                    <p className='flex gap-2 items-center text-center bg-customWhite p-2 inline-block rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2'> Profile
                        <img src={profileImg} className='w-7  inline-block' alt='profileImage' />
                    </p>
                </div>
            </div>

            <div className='flex flex-wrap gap-5'>
                <div className='p-2 border mt-3 ml-5 bg-customWhite inline-block rounded border-black border-t-2 border-r-4 border-b-4 border-l-2 min-w-72'>
                    <ProfilePic />
                    <ProfileDetails />
                    <ResetPassword />
                    <StrProfile />
                    <DeleteAccount />
                </div>
            </div>
        </AdminLayout >
    )
}

export default Profile;