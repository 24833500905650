import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { IoVideocamOutline } from "react-icons/io5";
import { MdSpatialAudioOff } from "react-icons/md";
import { PiChatTeardropTextBold } from "react-icons/pi";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { motion } from "framer-motion";
import giftImg from '../../Icons/gift.png'
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin } from '../../Redux/UserRedux/userAction';
import { createStripeCustomer, stripePayment } from '../../AxiosHelper/apiHelper';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import PulseLoader from "react-spinners/PulseLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricing = () => {
    const { adminUser } = useSelector((state) => state.admin);
    const [loading, setLoading] = useState(false);
    const [toggleOn, setToggleOn] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!adminUser) {
            dispatch(autoLogin());
        }
    }, [dispatch, adminUser]);

    const handelOnToggle = (e) => {
        setToggleOn(!toggleOn);
    };

    const handelOnFreePricing = () => {
        if (adminUser && adminUser?._id) {
            navigate('/signup');
            toast.success('You are already loggedin')
        }
        else {
            navigate('/signup');
        }
    }

    const handelOnPricing = async () => {
        setLoading(true);
        if (adminUser && adminUser?._id) {
            const subscriptionType = toggleOn ? 'yearly' : 'monthly';
            const obj = {
                userId: adminUser?._id,
                email: adminUser?.email,
                plan: subscriptionType,
            };
            // 1. Create a Stripe customer
            const { customerId } = await createStripeCustomer(obj);

            // 2. Create a Stripe Checkout Session
            const { sessionId } = await stripePayment({ ...obj, customerId });

            // 3. Redirect the user to the Stripe Checkout page
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId });
        } else {
            navigate('/signup');
        }
    };

    return (
        <Layout>
            {/* Header  */}
            <div className='text-center pt-30 pb-12 md:pt-38 md:pb-20 bg-customPink border-b-4 border-black font-sans'>
                <div className='mx-auto max-w-screen-sm p-4 '>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='font-bold text-5xl mt-10'>
                            Flexible plans that grow with you
                        </div>
                    </motion.div>
                    <p className='mt-3 text-lg mx-auto w-9/12 leading-5'>
                        Start collecting Testimony on our Free plan - no credit
                        card required, or trial Premium for unlimited
                        collection, multiple collection & more
                    </p>
                </div>
            </div>

            {/* Toogle button  */}
            <div className='mt-2 flex flex-wrap gap-2 justify-center items-center font-sans'>
                <h1 className='text-sm'>Pay Monthly</h1>
                {!toggleOn ? (
                    <button
                        className='text-lg p-2 px-4 rounded-sm focus:outline-none'
                        onClick={handelOnToggle}
                    >
                        <BsToggleOff className='text-4xl' />
                    </button>
                ) : (
                    <button
                        className=' text-white text-lg p-2 px-4 rounded-sm focus:outline-none'
                        onClick={handelOnToggle}
                    >
                        <BsToggleOn className='text-4xl text-black' />
                    </button>
                )}
                <h1 className='text-sm'> Pay Annually</h1>
                <div className='flex items-center gap-2 bg-customWhite shadow p-1 border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded'>
                    <p>25% off</p>
                    <img
                        src={giftImg}
                        alt='gitImf'
                        className='inline-block w-4 h-4'
                    />
                </div>
            </div>

            {/* Pricing card */}
            <div className='flex p-2 gap-2 justify-center flex-wrap '>
                {/* Free card  */}
                <div className='w-[400px] border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded p-2 bg-customOrange'>
                    <h1 className='text-sm'>Basic</h1>
                    <p className='text-2xl font-bold'>Free</p>
                    <p className='text-sm'> Free includes:</p>
                    <div className='mx-auto'>
                        <div className='mt-4'>
                            <span className='text-5xl font-bold'>$0</span>
                        </div>

                        <div className='flex gap-2 items-center'>
                            5<span className='text-sm font-bold'>X</span>{" "}
                            <PiChatTeardropTextBold className='inline-block' />2
                            <span className='text-sm font-bold'>X</span>
                            <IoVideocamOutline className='inline-block' />2{" "}
                            <span className='text-sm font-bold'>X</span>
                            <MdSpatialAudioOff className='inline-block' />
                        </div>

                        <div className='mt-3 leading-8 font-sans'>
                            <p>What you get with Free:</p>
                            <ol className='list-disc pl-5 leading-9'>
                                <li>5 Text Testimony</li>
                                <li>
                                    2 Video Testimony with 1 minutes
                                    limit
                                </li>
                                <li>
                                    Public Testimonial page, Wall of Love with
                                    our logo,Reports
                                </li>
                            </ol>
                        </div>
                        <div className='flex justify-center'>
                            <button
                                // to='/signup'
                                className='w-1/2 mt-5 text-center py-4 px-2 bg-white inline-block mt-3 hover:scale-110 transform transition-transform duration-400 border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded hover:cursor-pointer text-xl'
                                onClick={() => handelOnFreePricing('free')}
                                id='1'
                            >
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>

                {/* Paid Price  */}
                <div className='w-[400px] border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded p-2 bg-customBlue'>
                    <h1 className='text-sm'>Plus</h1>
                    <p className='text-2xl font-bold'>Plus</p>
                    <p className='text-sm'> Premium includes:</p>
                    <div className='mx-auto'>
                        <div className='mt-4'>
                            <span className='text-5xl font-bold'>
                                {toggleOn ? "$150" : "$15"}
                            </span>
                            <span> {toggleOn ? "per year" : "per month"}</span>
                        </div>
                        <div className='flex gap-2 items-center'>
                            30 <span className='text-sm font-bold'>X</span>{" "}
                            <PiChatTeardropTextBold className='inline-block' />
                            30<span className='text-sm font-bold'>X</span>
                            <IoVideocamOutline className='inline-block' />
                            30 <span className='text-sm font-bold'>X</span>{" "}
                            <MdSpatialAudioOff className='inline-block' />
                        </div>

                        <div className='mt-3 leading-8 font-sans'>
                            <p>What you get with Premium:</p>
                            <ol className='list-disc pl-5 leading-9'>
                                <li>Unlimited Text Testimony</li>
                                <li>
                                    30 Video Testimony with 5 minutes
                                    limit
                                </li>
                                <li>
                                    Public Testimonial page, Wall of Love with
                                    our logo,Reports
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <button
                            to='/signup'
                            className=' mt-5 text-center py-4 px-2 bg-white inline-block mt-3 hover:scale-110 transform transition-transform duration-400 border-black border-t-2 border-r-4 border-b-4 border-l-2 rounded hover:cursor-pointer text-xl'
                            onClick={() => {
                                handelOnPricing('paid');
                            }}
                            disabled={loading}
                        >
                            {loading ?
                                <div className='px-4 flex items-center justify-center gap-2'>
                                    Loading
                                    <PulseLoader
                                        color="#0b1215"
                                        loading={loading}
                                        size={10}
                                    />
                                </div> :
                                'Try Free for 14 Days'}
                        </button>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default Pricing;
