import React from 'react'
import { useDispatch } from 'react-redux';
import { deleteUserAction } from '../../../Redux/UserRedux/userAction';

const DeleteAccount = () => {
    const dispatch = useDispatch();

    const handelOnDelete = (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete the account?')) {
            dispatch(deleteUserAction())
        }
    }

    return (
        <div className='bg-customGray p-2 mt-5 rounded-xl text-lg'>
            <p className='text-black'>Delete your account</p>
            <p className='text-customBlue text-sm'>Deleting account is irreversible and will delete your subscription and testimony.</p>
            <button className='border rounded-lg p-2 shadow-lg mt-2 bg-customRed text-white w-30' onClick={handelOnDelete}>Delete Account</button>
        </div>
    )
}

export default DeleteAccount