import React from 'react'
import { AdminLayout } from '../../Layout/AdminLayout'
import homeImg from '../../Icons/home.png'

const Analytics = () => {
    return (
        <AdminLayout>
            <div>
                <div className='p-5 border-black border-b-2 border-bold '>
                    <div className='inline-block'>
                        <p className='flex gap-2 items-center text-center bg-customWhite p-2 inline-block rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                            Analytics
                            <img src={homeImg} alt='dashboardImg' className='w-7 h-7 inline-block' />
                        </p>
                    </div>
                </div>

                <div>

                </div>
            </div>
        </AdminLayout>
    )
}

export default Analytics