import { toast } from "react-toastify";
import { createForm, deleteForms, existingForm, saveFormInformation, uploadFormLogoTos3 } from "../../AxiosHelper/apiHelper";
import { setShowModal } from "../SystemUtils/modalSlice";
import { setCreatedTestimonyForms } from "./testimonySlice";

//create new form 
export const createFormAction = async (data) => {
    try {
        const responsePending = createForm(data)
        toast.promise(responsePending, { pending: "Please wait..." })
        const { status, message } = await responsePending
        toast[status](message);
        return responsePending
    } catch (error) {
        console.log(error.message)
    }
}

// get forms
export const getFormsAction = () => async (dispatch) => {
    try {
        const { status, forms } = await existingForm();
        status === 'success' && dispatch(setCreatedTestimonyForms(forms))
    } catch (error) {
        console.log(error)
    }
}

//saveForms
export const saveFormsAction = async (data) => {
    try {
        const { status, message } = await saveFormInformation(data);
        toast[status](message);
    } catch (error) {
        console.log(error)
    }
}
//delete forms  
export const deleteFormsAction = (data) => async (dispatch) => {
    try {
        const { status, message } = await deleteForms({ _id: data })
        toast[status](message);
        status === 'success' && dispatch(getFormsAction());
    } catch (error) {
        console.log(error)
    }
}

//upload logo in forms 
export const uploadLogoInFormAction = (data, _id) => async (dispatch) => {
    try {
        const responsePending = uploadFormLogoTos3(data, _id);
        toast.promise(responsePending, { pending: 'Please wait...' });
        const { status, message } = await responsePending;
        toast[status](message);
        status === 'success' && dispatch(getFormsAction()) && dispatch(setShowModal(false));
    } catch (error) {
        console.log(error.message);
    }
}


