import React, { useState } from 'react'
import { RequestOTP } from './RequestOTP'
import Layout from '../../Layout/Layout'
import ChangePasswordForm from './ChangePasswordForm'
import { requestOTPHelper, resetPasswordHelper } from '../../AxiosHelper/apiHelper'

const ForgotPassword = () => {
    const [passwordForm, setPasswordform] = useState("otp");
    const [resp, setResp] = useState({});
    const [passwordResponse, setPasswordResponse] = useState({});

    const handelOnOTP = async (email) => {
        if (!email) {
            return alert("Enter valid email");
        }
        const response = await requestOTPHelper({ email });
        setResp(response);
        response.status === 'success' && setPasswordform("password")
    }

    const handelOnPasswordUpdate = async (data) => {
        const response = await resetPasswordHelper(data)
        setPasswordResponse(response)
    }

    const form = {
        otp: <RequestOTP handelOnOTP={handelOnOTP} resp={resp} />,
        password: <ChangePasswordForm handelOnPasswordUpdate={handelOnPasswordUpdate} passwordResponse={passwordResponse} />
    }

    return (
        <Layout>
            {form[passwordForm]}
        </Layout>
    )
}

export default ForgotPassword