import React from 'react';
import { Link } from 'react-router-dom';
import { AdminLayout } from '../../Layout/AdminLayout';
import testimonyImg from '../../Icons/testimony.png';
import { PiChatTeardropTextBold } from "react-icons/pi";

const TestimonyCollector = () => {
    return (
        <AdminLayout>
            {/* Header  */}
            <div className='p-5 border-black border-b-2 border-bold '>
                <div className='inline-block'>
                    <p className='flex gap-2 items-center text-center bg-customWhite p-2 inline-block rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                        Testimony Collector
                        <img src={testimonyImg} alt='dashboardImg' className='w-7  inline-block' />
                    </p>
                </div>
            </div>

            {/* Body  */}
            <div className='mt-5 ml-5'>
                <div className='flex flex-wrap gap-5'>
                    {/* Text collector  */}
                    <div className="w-[400px] bg-customGreen overflow-hidden shadow-md p-2 rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2">
                        <div className="relative min-h-80 min-w-0 leading-6 bg-cover bg-center">
                            <p className='card_title text-white absolute mx-auto bottom-[30%] left-[5%] transform-translate-x-1/2 transform-translate-y-1/2 text-lg'>
                                Share a link. Get a text responses.
                            </p>
                            <div className='card_text text-white absolute mx-auto'>
                                <Link to="/testimonyCollector/text" className='flex p-2 text-base items-center gap-2 hover:cursor-pointer bg-black'>
                                    <PiChatTeardropTextBold className='inline-block' />Collect Text
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout >
    );
};

export default TestimonyCollector;
