import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

export const PrivatePages = ({ children }) => {
    const location = useLocation();

    const { adminUser } = useSelector((state) => state.admin)

    return adminUser?._id ? children : <Navigate to='/signIn' replace state={{ from: location }} />
}
