import React from 'react'
import Layout from '../../Layout/Layout'


const Features = () => {
    return (
        <Layout>
            <div className=''>Features</div>
        </Layout>
    )
}

export default Features