import { FiUser } from "react-icons/fi";
import { IoIosLogOut } from "react-icons/io";
import { PrivatePages } from '../Pages/PrivatePage/PrivatePages';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { adminLogout } from '../Redux/UserRedux/userAction';
import { IoHomeOutline } from "react-icons/io5";
import { BsCollectionPlay } from "react-icons/bs";
import { TbDeviceAnalytics } from "react-icons/tb";
import { useState } from "react";
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarRightCollapseFilled } from "react-icons/tb";
import { VscActivateBreakpoints } from "react-icons/vsc";

export const AdminLayout = ({ children }) => {
    const { adminUser } = useSelector((state) => state.admin);
    const [isMenuClose, setIsMenuClose] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleToggleMenu = (e) => {
        e.preventDefault()
        setIsMenuClose(!isMenuClose);
    }

    const handelOnLogout = (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to logout?')) {
            dispatch(adminLogout()) && navigate('/signIn');
        }
    }

    const handelOnActivate = () => {
        alert('Please check you email for activation link')
    }

    return (
        <PrivatePages>
            <div className='flex'>
                {/* Side bar */}
                <div className={`${!isMenuClose ? 'min-w-[150px]' : 'min-w-[280px]'} border-black border-r-2 border-bold bg-customMainWhite max-w-48`}>
                    <div className='flex flex-col justify-between min-h-[100vh]'>
                        {/* This div created just to push profile and logout button down */}
                        <div>
                            {/* Image and userprofile */}
                            <div className='flex justify-center py-2'>
                                {isMenuClose ? (
                                    <div className='mt-3 flex items-center justify-center text-center border p-2 hover:cursor-pointer shadow-lg hover:shadow-2xl transition-all duration-400 rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                                        <div className="min-h-10 min-w-10">
                                            <img src={adminUser?.imageUrl} className='w-10 h-10 rounded-3xl' alt='profileImg' />
                                        </div>
                                        <div>
                                            <p className='block text-sm underline-offset-3 mx-2'>
                                                Hi {adminUser?.fullName?.slice(0, 10) || adminUser?.fullName?.split(" ")[0]}
                                            </p>
                                            <p className='block text-xs max-w-42 underline-offset-3 mx-2 text-center text-customGrayWhite'>
                                                {adminUser?.email}!
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-3 flex items-center justify-center text-center hover:cursor-pointer hover:shadow-2xl transition-all duration-400 rounded-lg'>
                                        {adminUser?.images.map((imgLink, i) => (
                                            <img key={i} src={process.env.REACT_APP_SERVER_ROOT + imgLink} className='rounded-3xl' style={{ width: '40px', height: '40px' }} crossOrigin='anonymous' alt='profileImg' />
                                        ))}
                                    </div>
                                )}
                            </div>

                            <ul className='leading-loose border-black border-t-2  border-b-2'>
                                <li className='border-b-2 border-black p-2'>
                                    <Link
                                        to='/home'
                                        className={`flex items-center gap-2 ${location.pathname === "/home"
                                            ? "text-customPurple"
                                            : ""
                                            }`}
                                    >
                                        <IoHomeOutline className='inline-block text-2xl' />{" "}
                                        Home
                                    </Link>
                                </li>
                                <li className='border-b-2 border-black p-2'>
                                    <Link
                                        to='/testimonyCollector'
                                        className={`flex items-center gap-2 ${location.pathname ===
                                            "/testimonyCollector"
                                            ? "text-customPurple"
                                            : ""
                                            }`}
                                    >
                                        <BsCollectionPlay className='inline-block text-2xl' />{" "}
                                        Testimony Collector
                                    </Link>
                                </li>
                                <li className='border-black p-2'>
                                    <Link
                                        to='/analytics'
                                        className={`flex items-center gap-2 ${location.pathname === "/analytics"
                                            ? "text-customPurple"
                                            : ""
                                            }`}
                                    >
                                        <TbDeviceAnalytics className='inline-block text-2xl' />{" "}
                                        Analytics
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {/* This div created to push logout and profile butto on footer  */}
                        <div className="relative">
                            {/* Sidebar squeezer  */}
                            <div className="absolute top-[-25px] right-[-5px] hover:cursor-pointer ">
                                {!isMenuClose ? (
                                    <div>
                                        <TbLayoutSidebarRightCollapseFilled onClick={handleToggleMenu} className="text-3xl transition-all duration-400 " />
                                    </div>
                                ) : (
                                    <div>
                                        <TbLayoutSidebarLeftCollapseFilled onClick={handleToggleMenu} className="text-3xl  transition-all duration-400 " />
                                    </div>
                                )}
                            </div>
                            <div className='flex items-center gap-2 py-4 hover:text-customPurple cursor-pointer border-black p-2 border-t-2'>
                                <ul>
                                    <li className='border-black'>
                                        <Link
                                            to='/profile'
                                            className={`flex items-center gap-2 ${location.pathname === "/profile"
                                                ? "text-customPurple"
                                                : ""
                                                }`}
                                        >
                                            <FiUser className='inline-block text-2xl' />{" "}
                                            Profile
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div
                                className='flex items-center gap-2 py-4 hover:text-customPurple cursor-pointer border-black p-2 border-t-2'
                                onClick={handelOnLogout}
                            >
                                <IoIosLogOut className='inline-block text-2xl' />{" "}
                                LogOut
                            </div>
                            {
                                adminUser?.status === "inactive" &&
                                <div
                                    className='items-center gap-2 py-4 hover:text-customPurple cursor-pointer border-black p-2 border-t-2'
                                    onClick={handelOnActivate}
                                >
                                    <VscActivateBreakpoints className='inline-block text-2xl' />{" "}
                                    Status: Inactive

                                </div>
                            }

                        </div>
                    </div>
                </div>

                {/* Children component */}
                <div className='flex-1 h-[100vh] overflow-y-auto bg-white'>
                    <div>{children}</div>
                </div>
            </div>
        </PrivatePages >
    );
};
