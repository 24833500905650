import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { useDispatch, useSelector } from 'react-redux';
import { autoLogin } from '../../Redux/UserRedux/userAction';

const Header = () => {
    const { adminUser } = useSelector((state) => state.admin);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!adminUser) {
            dispatch(autoLogin());
        }
    }, [dispatch, adminUser]);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 640);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className='font-sans'>

            <nav
                className={`flex justify-between items-center p-2 border-black  border-b-2 border-bold mx-auto bg-white ${isMobile ? "block" : "hidden"
                    }`}
            >
                <ul>
                    <li className='text-4xl font-bold drop-shadow-lg'>
                        <Link to='/'>TP.</Link>
                    </li>
                </ul>

                <div className='text-2xl cursor-pointer' onClick={toggleMenu}>
                    {isMenuOpen ? <VscChromeClose /> : <GiHamburgerMenu />}
                </div>
            </nav>

            {/* on mobile view  */}
            {isMobile && isMenuOpen && (
                <nav className='flex text-xl p-2 border-black border-b-2 bg-white '>
                    <ul className='flex flex-col gap-3 items-start'>
                        <li className='hover:cursor-pointer'>
                            <Link to='/features'>Features</Link>
                        </li>
                        {/* <li className='hover:cursor-pointer'>
                            <Link to='/integrations'>Integration</Link>
                        </li> */}
                        <li className='hover:cursor-pointer'>
                            <Link to='/pricing'>Pricing</Link>
                        </li>
                        {/* <li className='hover:cursor-pointer'>
                            <Link to='/about'>About</Link>
                        </li> */}
                        <li className={`border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 py-${adminUser ? "2" : "0"} rounded hover:cursor-pointer bg-customMainWhite hover:scale-110 transform transition-transform duration-400`}>
                            {adminUser ? <Link to='/home'>Dashboard</Link> : <Link to='/signin'>Signin</Link>}
                        </li>
                    </ul>
                </nav>
            )}

            {/* on web view */}
            {!isMobile && (
                <nav className='flex text-xl justify-between justify-around mx-auto p-4 border-black  border-b-2 border-bold mx-auto bg-white'>
                    <ul>
                        <li className='text-4xl font-bold drop-shadow-lg'>
                            <Link to='/'>TP.</Link>
                        </li>
                    </ul>
                    <ul className='flex justify-between gap-5 items-center'>
                        <li className='hover:cursor-pointer'>
                            <Link to='/features'>Features</Link>
                        </li>
                        {/* <li className='hover:cursor-pointer'>
                            <Link to='/integrations'>Integration</Link>
                        </li> */}
                        <li className='hover:cursor-pointer'>
                            <Link to='/pricing'>Pricing</Link>
                        </li>
                        {/* <li className='hover:cursor-pointer'>
                            <Link to='/about'>About</Link>
                        </li> */}

                        <li className={`border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 py-${adminUser ? "2" : "0"} rounded hover:cursor-pointer bg-customMainWhite hover:scale-110 transform transition-transform duration-400`}>
                            {adminUser ? <Link to='/home'>Dashboard</Link> : <Link to='/signin'>Signin</Link>}
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
};

export default Header;
