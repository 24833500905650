import { toast } from "react-toastify";
import { setAdminUser } from "./userSlice";
import { deleteImage, deleteUser, getAdminUser, getNewAccessJWT, updatePassword, updateProfile, uploadFileToS3 } from "../../AxiosHelper/apiHelper";

// export const loginUserAction = (data) => async (dispatch) => {
//     const resultPromise = await loginAdminUser(data);
//     const { status, message, user, accessJWT, refreshJWT } = resultPromise;
//     toast[status](message);
//     if (status === 'success') {
//         sessionStorage.setItem('accessJWT', accessJWT)
//         localStorage.setItem('refreshJWT', refreshJWT)
//         dispatch(setAdminUser(user))
//     }
// }

export const adminLogout = () => dispatch => {
    dispatch(setAdminUser(null));
    sessionStorage.removeItem('accessJWT')
    localStorage.removeItem('refreshJWT')
}

//fet user and mount in the redux store
export const getAdminUserAction = (token) => async (dispatch) => {
    const { status, user } = await getAdminUser(token)
    status === 'success' && dispatch(setAdminUser(user))
}

export const autoLogin = () => async (dispatch) => {
    const accessJWT = sessionStorage.getItem('accessJWT');
    const refreshJWT = localStorage.getItem('refreshJWT');
    if (accessJWT) {
        //if accessJWt exist, fetch user and mount the user on redux store
        dispatch(getAdminUserAction())
    }
    else if (refreshJWT) {
        //if refresh jwt exist only, fetch new accessjwt and fetch user using the newly fetch accessjwt
        const token = await getNewAccessJWT();
        token ? dispatch(getAdminUserAction(token)) : dispatch(adminLogout());
    }
    else {
        dispatch(adminLogout())
    }
}

export const updateProfileAction = (data) => async dispatch => {
    try {
        const responsePending = updateProfile(data);
        toast.promise(responsePending, { pending: 'Please wait...' });
        const { status, message } = await responsePending;
        toast[status](message);
        status === 'success' && dispatch(getAdminUserAction())
    } catch (error) {
        console.log(error.message)
    }
}

export const updatePasswordAction = (data) => async dispatch => {
    try {
        const responsePending = updatePassword(data);
        toast.promise(responsePending, { pending: 'Please wait...' });
        const { status, message } = await responsePending;
        toast[status](message);
        status === 'success' && dispatch(getAdminUserAction())
    } catch (error) {
        console.log(error.message)
    }
}

export const deleteUserAction = (data) => async (dispatch) => {
    try {
        const responsePending = deleteUser({ data });
        toast.promise(responsePending, { pending: 'Please wait...' });
        const { status, message } = await responsePending;
        toast[status](message);
        status === 'success' && dispatch(adminLogout())
    } catch (error) {
        console.log(error.message)
    }
}

export const profileImgUploadAction = (data) => async (dispatch) => {
    try {
        const responsePending = uploadFileToS3(data);
        toast.promise(responsePending, { pending: 'Please wait...' });
        const { status, message } = await responsePending;
        toast[status](message);
        status === 'success' && dispatch(getAdminUserAction());
    } catch (error) {
        console.log(error.message);
    }
};

export const deleteImageAction = (data) => async (dispatch) => {
    try {
        const responsePending = deleteImage(data);
        toast.promise(responsePending, { pending: 'Please wait...' });
        const { status, message } = await responsePending;
        toast[status](message);
        status === 'success' && dispatch(getAdminUserAction())
    } catch (error) {
        console.log(error.message)
    }
}

