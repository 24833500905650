import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteFormsAction, getFormsAction } from '../../Redux/Testimony/testimonyAction';
import { SiGoogleforms } from "react-icons/si";
import { useNavigate } from 'react-router-dom';
import { getFormsById } from '../../AxiosHelper/apiHelper';

const FormsTable = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userCreatedForms } = useSelector((state) => state.testimony);

    useEffect(() => {
        dispatch(getFormsAction())
    }, [dispatch]);

    const handelOnDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this item, you wont be able ro recover it.")) {
            dispatch(deleteFormsAction(id));
        }
    }

    const handelOnEdit = async (id) => {
        const { status, forms, message } = await getFormsById(id);
        if (status === 'success' && forms && forms._id) {
            const { _id, formName } = forms
            navigate(`/${formName}/${_id}`);
        }
        else {
            alert(message)
        }
    }

    return (
        <div >
            <div className='mb-5' >
                <p className='text-xl font-bold'>Your Testimonails</p>
            </div>
            {userCreatedForms.length > 0 &&
                <div className='border w-full flex gap-2 bg-green p-5 min-h-42 max-h-96 overflow-x-scroll flex-wrap '>
                    {userCreatedForms?.map((item, i) => (
                        <div className='rounded flex items-center justify-between flex-wrap shadow px-4 bg-customWhite p-2  rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2 max-w-[300px] min-h-[200px] w-80' key={i}>
                            <div className='flex items-center gap-2 text-senjaGray text-sm justify-center'>
                                <div className='min-w-[50px] min-h-[10px]'>
                                    {
                                        item?.imageUrl ?
                                            <img

                                                src={item?.imageUrl}
                                                className='rounded-full h-[80px] w-[80px]'
                                                alt='profileImg'
                                            /> : <SiGoogleforms className='text-5xl text-customLightPurple' />
                                    }
                                </div>
                                <div>
                                    <h1 className='text-black text-base'>{item?.formName?.toUpperCase()}</h1>
                                    <p>{item?.headings}</p>
                                    <p className='text-senjaGray'>{item?.title}</p>
                                </div>
                            </div>
                            <div className='flex items-center gap-2 '>
                                <button className='rounded shadow px-4 bg-customWhite p-2 inline-block rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2 w-32' onClick={() => handelOnEdit(item?._id)}>Edit form</button>
                                <button className='rounded shadow px-4 bg-customWhite p-2 inline-block rounded-lg border-black border-t-2 border-r-4 border-b-4 border-l-2 w-32' onClick={() => handelOnDelete(item?._id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            }

            <div>
                <img src='' alt='profilePic' />

            </div>

        </div >
    )
}

export default FormsTable