import React, { useEffect, useState } from 'react'
import CustomInput from '../../../Components/ReusableComponents/CustomInput'
import { updateProfileAction } from '../../../Redux/UserRedux/userAction';
import { useDispatch, useSelector } from 'react-redux';

const ProfileDetails = () => {
    const { adminUser } = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    const [form, setForm] = useState({});

    useEffect(() => {
        setForm(adminUser)
    }, [adminUser])


    const handelOnChange = (e) => {
        let { name, value } = e.target;
        setForm({ ...form, [name]: value });
    }

    const handelOnSubmit = (e) => {
        e.preventDefault();
        const { createdAt, password, refreshJWT, status, updatedAt, __v, images, isPaid, ...rest } = form;
        dispatch(updateProfileAction(rest))
    };

    const inputs = [
        {
            label: 'Full Name',
            name: 'fullName',
            type: 'text',
            required: true,
            value: form.fullName
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'johnDoe@gmail.com',
            required: true,
            value: form.email,
            disabled: true
        },
    ];

    return (
        <form className='mt-3' onSubmit={handelOnSubmit} encType='multipart/form-data'>
            <div>
                {inputs.map((input, index) => (
                    <CustomInput {...input} key={index}
                        onChange={handelOnChange} />
                ))}
                <button className='border rounded-lg p-2 shadow-lg bg-customPink w-36 border-black border-t-2 border-r-4 border-b-4 border-l-2' type='submit'>
                    Save changes
                </button>
            </div>
        </form>
    )
}

export default ProfileDetails;