import React from 'react';
import { PrivatePages } from '../../../PrivatePage/PrivatePages';
import TestimonyCollectorLayout from '../../TcLayout';
import { useSelector } from 'react-redux';

const DrakeForm = () => {
    const { currentTestimony } = useSelector((state) => state.testimony);

    return (
        <PrivatePages>
            <TestimonyCollectorLayout>
                <div className="absolute inset-0" style={{
                    backgroundImage: `linear-gradient(to right, ${currentTestimony?.leftColor}, ${currentTestimony?.rightColor || '#A388EE'})`,
                    clipPath: 'polygon(0 0, 100% 0, 100% 20%, 0 72%)'
                }}>
                </div>

                <div className="w-[450px] max-h-[550px] overflow-y-auto border rounded p-10 bg-white z-10 shadow-2xl ">
                    <div className='d-flex flex-wrap text-black p-2'>
                        {currentTestimony?.images?.length > 0 ? (
                            <img src={currentTestimony?.imageUrl} className='rounded-full h-[80px] w-[80px]' alt='profileLogo' />
                        ) : (
                            <img className='rounded-full h-[80px] w-[80px] bg-customPink' alt='profileLogo' />
                        )}
                        <h1 className='text-xl mt-5'>{
                            currentTestimony?.headings}
                        </h1>

                        <p className='text-senjaGray mt-5 text-base' style={{ whiteSpace: 'pre-line' }}>
                            {currentTestimony?.questions}
                        </p>

                        <div className='flex mt-5 justify-center text-center flex-col gap-2'>
                            <button className='bg-customPurple text-white p-3 rounded w-full text-lg'>Submit</button>
                            <button className='bg-customGrayWhite text-white p-3 rounded w-full text-lg'>Share your testimony</button>
                        </div>
                    </div>
                </div>

            </TestimonyCollectorLayout>
        </PrivatePages >
    );
};

export default DrakeForm;
