import React, { useState } from 'react'
import { IoIosCheckmark } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { profileImgUploadAction } from '../../../Redux/UserRedux/userAction';

const ProfilePic = () => {
    const [serverImg, setServerImg] = useState(null);
    const [images, setImages] = useState([]);
    const dispatch = useDispatch()
    const { adminUser } = useSelector((state) => state.admin);

    const handelOnImageSelect = e => {
        const { files } = e.target;
        if (files.length > 0) {
            const imageUrl = URL.createObjectURL(files[0]);
            setServerImg(imageUrl);
        }
        setImages(files)
    }

    const handelOnSave = (e) => {
        e.preventDefault();
        for (const image of images) {
            dispatch(profileImgUploadAction(image));
        }
        setImages([]);
    };

    const handelOnRemove = () => {
        setImages([]);
        setServerImg([]);
    }

    const imagesInput = [
        {
            name: 'images',
            type: 'file',
            accept: 'image/*',
        }
    ];

    return (
        <div className='p-4 rounded-xl mb-2'>
            <p className='text-black'>Personal information</p>
            <div>
                <p className='text-sm mb-2'>Your avatar</p>
                <div className='flex gap-2 items-center text-center ' >
                    {
                        serverImg ? (
                            <img src={serverImg} className='w-10 h-10' crossOrigin='anonymous' alt='profileImg' />
                        ) : (
                            <img src={adminUser?.imageUrl} className='w-10 h-10' alt='profileImg' />
                        )
                    }
                    <form onSubmit={handelOnSave} encType='multipart/form-data'>
                        {imagesInput.map((input, index) => (
                            <div key={index}>
                                {images.length ? (
                                    <div className='flex w-40 text-center items-center gap-2'>
                                        <button
                                            className='text-black  hover:cursor-pointer text-4xl'
                                            type='submit'
                                        > <IoIosCheckmark className='inline-block' />
                                        </button>
                                        <button
                                            className='text-black  hover:cursor-pointer text-3xl'>
                                            <IoIosClose className='inline-block' onClick={handelOnRemove} />
                                        </button>
                                    </div>
                                ) : (
                                    <div className='flex gap-2 items-center'>
                                        <label className='border rounded p-1 shadow-lg bg-white text-black  hover:cursor-pointer  border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                                            <input
                                                type="file"
                                                name={input.name}
                                                accept={input.accept}
                                                onChange={handelOnImageSelect}
                                                style={{ display: 'none' }} />
                                            Pick an image
                                        </label>

                                    </div>
                                )}
                            </div>
                        ))}
                    </form>
                </div>
            </div>
        </div>

    )
}

export default ProfilePic