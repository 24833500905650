import React, { useState } from 'react'
import CustomInput from '../../Components/ReusableComponents/CustomInput'

export const RequestOTP = ({ resp, handelOnOTP }) => {
    const [email, setEmail] = useState("");

    const handelOnChange = (e) => {
        setEmail(e.target.value);
    }

    const handelOnSumbit = (e) => {
        e.preventDefault();
        handelOnOTP(email)
    }

    const inputs = [
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'johnDoe@gmail.com',
            required: true,
        }]

    return (
        <div className='w-96 flex flex-col rounded mx-auto items-center justify-center pt-32 pb-12 md:pt-38 md:pb-20'>
            <h1 className='text-2xl font-Roboto font-bold flex items-center gap-2'>Reset your password</h1>
            <div className='mt-5 max-w-md mx-auto bg-white py-8 px-4 shadow sm:rounded sm:px-10 border-black border-t-2 border-r-4 border-b-4 border-l-2'>

                <p className='text-red-500'>{resp.message}</p>

                <form className='mt-5' onSubmit={handelOnSumbit}>
                    {inputs.map((input, index) => (
                        <CustomInput
                            {...input}
                            key={index}
                            onChange={handelOnChange}
                            required={true}
                        />
                    ))}
                    <button
                        className='w-full p-2 mt-2 border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 rounded hover:cursor-pointer bg-customMainWhite hover:scale-105 transform transition-transform duration-400 flex justify-center items-center '
                        type='submit'
                    >
                        Request OTP
                    </button>
                </form>
            </div>
        </div>
    )
}
