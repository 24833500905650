import React from 'react'
import Layout from '../../Layout/Layout'

const Integrations = () => {
    return (
        <Layout>
            <div className=''>Integrations</div>
        </Layout>
    )
}

export default Integrations