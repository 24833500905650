import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import LogoUploader from '../../Pages/TestimonyCollector/CompanyLogo/LogoUploader';
import { setShowModal } from '../../Redux/SystemUtils/modalSlice';

const Modal = ({ heading, children }) => {
    const dispatch = useDispatch();
    const { profileImageUploaderModal } = useSelector((state) => state.modal);

    useEffect(() => {
        const handleEscapeKeyPress = (event) => {
            if (event.key === 'Escape') {
                dispatch(setShowModal(false));
            }
        };
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.modal-content')) {
                dispatch(setShowModal(false));
            }
        };
        if (profileImageUploaderModal) {
            document.addEventListener('keydown', handleEscapeKeyPress);
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('keydown', handleEscapeKeyPress);
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [profileImageUploaderModal, dispatch]);

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center ${profileImageUploaderModal ? '' : 'hidden'}`}>
            <div className="fixed inset-0 bg-customGrayWhite opacity-50"></div>
            <div className="bg-white p-4 rounded-lg z-10 sm:w-96 md:w-96 lg:w-96 xl:w-96 relative modal-content">
                <div className="flex items-center justify-center">
                    <h3 className="text-lg text-senjaGray text-center text-h3">{heading}</h3>
                </div>
                <div className='bg-white p-3 rounded-lg text-center'>
                    {profileImageUploaderModal ? <LogoUploader /> : children || ''}
                </div>
            </div>
        </div>
    )
}

export default Modal;