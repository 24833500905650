import React, { useState } from 'react'
import CustomInput from '../../Components/ReusableComponents/CustomInput'

const ChangePasswordForm = ({ handelOnPasswordUpdate, passwordResponse }) => {
    const [form, setForm] = useState({});
    const [error, setError] = useState(null);

    const handelOnchange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
        if (name === "password") {
            if (!value) {
                setError("Password must be provided");
            } else if (value.length < 6) {
                setError("Password must be at least 6 characters long");
            } else if (!/\d/.test(value)) {
                setError("Password must contain at least 1 number");
            } else if (!/[a-z]/.test(value)) {
                setError("Password must contain at least 1 alphabet");
            } else {
                setError(null);
            }
        }
    }

    const handelOnSumbit = (e) => {
        e.preventDefault();
        try {
            handelOnPasswordUpdate(form)
        } catch (error) {
            console.log(error)
        }
    }

    const inputs = [
        {
            label: 'Check your email for OTP',
            name: 'otp',
            type: 'text',
            placeholder: "Check your email",
            required: true,
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            placeholder: 'johnDoe@gmail.com',
            required: true,
        },
        {
            label: 'New Password',
            name: 'password',
            type: 'password',
            placeholder: '********',
            required: true,
        },

    ]

    return (
        <div className='w-full flex flex-col rounded mx-auto items-center justify-center pt-32 pb-12 md:pt-38 md:pb-20'>
            <h1 className='text-2xl font-Roboto font-bold flex items-center gap-2'>Reset New Password</h1>

            <div className='w-96 mt-5 max-w-md mx-auto bg-white py-8 px-4 shadow sm:rounded sm:px-10 border-black border-t-2 border-r-4 border-b-4 border-l-2'>
                <p className='mx-auto text-center text-red-500'>{passwordResponse.message}</p>
                <form className='mt-5' onSubmit={handelOnSumbit}>
                    {inputs.map((input, index) => (
                        <CustomInput
                            {...input}
                            key={index}
                            onChange={handelOnchange}
                            required={true}
                        />
                    ))}

                    {error ? (
                        <p className='text-red-500'>{error}</p>
                    ) : (
                        <p className='text-customBlue'>To create a strong password, use at least 6 words and a combination of letters and numbers</p>
                    )}

                    <button
                        className='w-full p-2 mt-2 border-black border-t-2 border-r-4 border-b-4 border-l-2 px-2 rounded hover:cursor-pointer bg-customMainWhite hover:scale-105 transform transition-transform duration-400 flex justify-center items-center '
                        type='submit'
                    >
                        Change Password
                    </button>
                </form>
            </div>
        </div >
    )
}

export default ChangePasswordForm