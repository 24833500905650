import axios from 'axios'

const rootUrl = process.env.REACT_APP_API_ENDPOINT;
const adminUserEp = rootUrl + '/admin-user'
const stripeEp = rootUrl + '/stripe'
const txQuestionEp = rootUrl + '/txQuestionForm'

const apiProcessor = async ({ method, url, data, isPrivate, token }) => {
    try {
        let headers = isPrivate ? { Authorization: token || sessionStorage.getItem('accessJWT') } : null
        const response = await axios({
            method,
            url,
            data,
            headers,
        })
        return response.data
    } catch (error) {
        let message = error.message
        console.log(message)
        if (error.response) {
            return {
                status: 'error',
                message
            }
        }

    }
}

//register admin user 
export const createUser = (data) => {
    const option = {
        method: 'post',
        url: adminUserEp,
        data
    }
    return apiProcessor(option)
}

//login admin user 
export const loginAdminUser = (data) => {
    const option = {
        method: 'post',
        url: adminUserEp + '/login',
        data,
    }
    return apiProcessor(option)
}

//get admin user
export const getAdminUser = (token) => {
    const option = {
        method: 'get',
        url: adminUserEp,
        isPrivate: true,
        token
    }
    return apiProcessor(option);
}

export const getNewAccessJWT = async () => {
    const option = {
        method: 'get',
        url: adminUserEp + '/accessJWT',
        isPrivate: true,
        token: localStorage.getItem('refreshJWT'),
    };
    const { status, accessJWT } = await apiProcessor(option);
    status === 'success' && sessionStorage.setItem('accessJWT', accessJWT);
    return accessJWT
}

//update admin profile
export const updateProfile = (data) => {
    const option = {
        method: 'patch',
        url: adminUserEp + '/updateProfile',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

//update password
export const updatePassword = (data) => {
    const option = {
        method: 'patch',
        url: adminUserEp + '/updatePassword',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

//delete user
export const deleteUser = (data) => {
    const option = {
        method: 'delete',
        url: adminUserEp,
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}


//deleteImage from server
export const deleteImage = (data) => {
    const option = {
        method: 'delete',
        url: adminUserEp + '/deleteImage',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

// stripe payment
export const stripePayment = (data) => {
    const option = {
        method: 'post',
        url: stripeEp + '/create-checkout-session',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

// creting stripe customer  payment
export const createStripeCustomer = (data) => {
    const option = {
        method: 'post',
        url: stripeEp + '/create-customer',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

//verifyEmail 
export const emailVerification = (data) => {
    const option = {
        method: 'patch',
        url: adminUserEp + '/verifyEmail',
        // isPrivate: true,
        data
    }
    return apiProcessor(option)
}
//request OTP 
export const requestOTPHelper = (data) => {
    const option = {
        method: 'post',
        url: adminUserEp + '/requestOTP',
        data
    }
    return apiProcessor(option)
}

//reset password
export const resetPasswordHelper = (data) => {
    const option = {
        method: 'patch',
        url: adminUserEp + '/requestResetPassword',
        data
    }
    return apiProcessor(option)
}

export const createForm = (data) => {
    const option = {
        method: 'post',
        url: txQuestionEp + '/createForm',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

export const existingForm = () => {
    const option = {
        method: 'get',
        url: txQuestionEp + '/getForms',
        isPrivate: true,
    }
    return apiProcessor(option)
}

export const getFormsById = (id) => {
    const option = {
        method: 'get',
        url: `${txQuestionEp}/getFormsById?_id=${id}`,
        isPrivate: true
    }
    return apiProcessor(option);
}

export const saveFormInformation = (data) => {
    const option = {
        method: 'patch',
        url: txQuestionEp + '/saveForm',
        isPrivate: true,
        data
    }
    return apiProcessor(option);
}

export const deleteForms = (data) => {
    const option = {
        method: 'delete',
        url: txQuestionEp + '/deleteForms',
        isPrivate: true,
        data
    }
    return apiProcessor(option)
}

export const getUploadUrlForProfilePic = async (file) => {
    const option = {
        method: 'get',
        url: `${adminUserEp}/profileUpload?filename=${encodeURIComponent(file.name)}&filetype=${encodeURIComponent(file.type)}`,
        isPrivate: true,
    }
    const response = await apiProcessor(option)
    const { status, message, uploadUrl } = response;
    return { status, message, uploadUrl };
}

export const uploadFileToS3 = async (file) => {
    try {
        const { status, message, uploadUrl } = await getUploadUrlForProfilePic(file);
        await axios.put(uploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        return { status, message };
    } catch (error) {
        console.error(error);
    }
};

export const getUploadUrlForFormPic = async (file, _id) => {
    const option = {
        method: 'get',
        url: `${txQuestionEp}/uploadLogo?filename=${encodeURIComponent(file.name)}&filetype=${encodeURIComponent(file.type)}&_id=${_id}`,
        isPrivate: true,
    }
    const response = await apiProcessor(option)
    const { status, message, uploadUrl } = response;
    return { status, message, uploadUrl };
}


export const uploadFormLogoTos3 = async (file, _id) => {
    try {
        const { status, message, uploadUrl } = await getUploadUrlForFormPic(file, _id);
        await axios.put(uploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        return { status, message };
    } catch (error) {
        console.error(error);
    }
};