import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentTestimony: {},
    userCreatedForms: [],
}

const testimonySlice = createSlice({
    name: 'testimony',
    initialState,
    reducers: {
        setCurrentTestimony: (state, { payload }) => {
            state.currentTestimony = payload;
        },
        setCreatedTestimonyForms: (state, action) => {
            state.userCreatedForms = action.payload;
        },
    },
});

const { reducer, actions } = testimonySlice;
export const { setCurrentTestimony, setCreatedTestimonyForms } = actions;
export default reducer;